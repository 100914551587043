import React, { useState } from 'react';
import { Icon } from '@iconify/react';
import MenuList from '@mui/material/MenuList';
import { Nav } from '../utils';
import PropTypes from 'prop-types';
import DrawerWithSearch from './helpers/DrawerWithSearch';
import { useNavigate, NavLink } from 'react-router-dom';
import { alpha , Box, Portal } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


const MenuPopper = ({ open, handleClose, activeId }) => {
  const [portal, setPortal] = useState(null);
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const [portalActive, setPortalActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeMenu, setActiveMenu] = useState(activeId);
  const [searchResults, setSearchResults] = useState([]);

  const container = React.useRef(null);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    const results = Nav[activeMenu]?.child?.filter((item) =>
      item.name.toLowerCase().includes(event.target.value.toLowerCase()),
    );
    setSearchResults(results);
  };

  const handleChange = (event, newValue, i) => {
    setValue(newValue);
    setActiveMenu(event.target.id);
  };

  const MenuItemLoop = ({ item, i, path }) => {
    return (
      <React.Fragment key={i}>
        <Box
          component='li'
          sx={{
            '& .expand-link': {
              bgcolor: i === portal ? 'background.light' : 'none',
              '&:hover': {
                bgcolor: 'background.light',
              },
            },
            '& a': {
              fontWeight: 600,
              '&:hover': {
                color: 'primary.main',
              },
              '&.active': {
                color: 'primary.main',
                fontWeight: 700,
              },
            },
          }}
        >
          {item.children === null ? (
            <NavLink
              to={item.path}
              className='flex gap-3 py-2 px-3 items-center'
              title={item.name}
            >
              <Box className='text-lg'>{item.icon}</Box>
              <Box className='flex-1 whitespace-nowrap text-ellipsis overflow-hidden'>
                {item.name}
              </Box>
            </NavLink>
          ) : (
            <Box
              className='flex gap-3 py-2 px-3 items-center cursor-pointer expand-link inline-block'
              title={item.name}
              data-key={i}
              sx={{ fontWeight: 600 }}
              onClick={() => {
                setPortal(i);
                setPortalActive(i === portal && !portalActive);
                item.children === null && navigate(item.path);
              }}
            >
              <Box className='text-lg'>{item.icon}</Box>
              <Box className='flex-1 whitespace-nowrap text-ellipsis overflow-hidden'>
                {item.name}
              </Box>
              <Box>
                <Icon
                  icon={
                    !portalActive && i === portal
                      ? 'la:angle-left'
                      : 'la:angle-right'
                  }
                />
              </Box>
            </Box>
          )}
        </Box>

        {item?.children && !portalActive && i === portal && (
          <Portal container={() => container.current}>
            <li className='w-full p-4'>
              <Box sx={{ fontWeight: 700 }} className='mb-2'>
                {item.name}
              </Box>
              <MenuList
                className='w-full pt-0 m-0'
                sx={{
                  fontWeight: 400,
                  '& .active': {
                    color: 'primary.main',
                    fontWeight: 700,
                  },
                }}
              >
                {item?.children?.map((item1, ic) => {
                  return (
                    <NavLink
                      key={ic}
                      to={item1?.path}
                      className='flex items-center py-1.5'
                      title={item1?.name}
                    >
                      <Box className='flex-1 whitespace-nowrap text-ellipsis overflow-hidden'>
                        {item1?.name}
                      </Box>
                    </NavLink>
                  );
                })}
              </MenuList>
            </li>
          </Portal>
        )}
      </React.Fragment>
    );
  };

  return (
    <>
      <DrawerWithSearch
        open={open}
        onClose={handleClose}
        searchPlaceholder={`Search Menu`}
        searchValue={searchTerm}
        searchHandleChange={(event) => handleSearchChange(event)}
        disableRestoreFocus={false}
        headerBottom={
          <Tabs
            value={value}
            onChange={(event, i) => handleChange(event, i)}
            variant='scrollable'
            sx={{
              minHeight: 'inherit',
              border: '1px solid',
              borderColor: (theme) => alpha(theme.palette.text.dark, 0.1),
              '& .MuiTabs-indicator': {},

              '& .MuiButtonBase-root': {
                color: 'inherit',
                fontWeight: 700,
                padding: '10px',
                textTransform: 'uppercase',
                '&.Mui-selected': {
                  color: 'primary.main',
                },
                '& svg': {
                  marginRight: '10px',
                  fontSize: '20px',
                },
              },
              '& .MuiTabs-scrollButtons.Mui-disabled': {
                opacity: 0.3,
              },
              '& .MuiTab-iconWrapper': {
                pointerEvents: 'none',
              },
              '& .MuiTouchRipple-root': {
                pointerEvents: 'none',
              },
            }}
          >
            {Nav.map((item, i) => {
              return (
                item.name && (
                  <Tab id={i} key={i} icon={item?.icon} label={item?.name} />
                )
              );
            })}
          </Tabs>
        }
      >
        <Box
          className='flex'
          sx={{ minHeight: 'calc(100vh - 180px)', bgcolor: 'background.light' }}
        >
          <Box
            sx={{
              minHeight: '100%',
              flex: 1,
              minWidth: 0,
              bgcolor: 'background.white',
              borderRight: '1px solid',
              borderColor: 'border.main',
            }}
          >
            <MenuList className='p-0 outline-none'>
              {searchTerm?.length > 0
                ? searchResults?.map((item, i) => (
                    <React.Fragment key={i}>
                      <MenuItemLoop item={item} i={i} />
                    </React.Fragment>
                  ))
                : Nav[activeMenu].child?.map((item, i) => (
                    <React.Fragment key={i}>
                      <MenuItemLoop item={item} i={i} path={Nav[activeMenu]} />
                    </React.Fragment>
                  ))}
            </MenuList>
          </Box>
          <Box className='flex-1 min-w-0'>
            <MenuList className='p-0' ref={container} />
          </Box>
        </Box>
      </DrawerWithSearch>
    </>
  );
};

MenuPopper.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  activeId: PropTypes.number,
};

export default MenuPopper;
