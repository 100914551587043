import React, { useState } from 'react';
import { Box, Grid } from '@mui/material';
import { Icon } from '@iconify/react';
import { useForm } from 'react-hook-form';
import Crud_Service from '../../../apis/CrudService';
import { ActionButtons } from '../../../components/_form';
import { StatusBadge, ViewField } from '../../../components/_form/FormElements';
import TableActionButtons from '../../../components/_form/TableActionButtons';
import MuiDialogOne from '../../../components/_mui/MuiDialogOne';
import DataTable from '../../../components/DataTable.tsx';
import CommonLoader from '../../../components/page/CommonLoader';
import PageHeader from '../../../components/PageHeader';
import { CreateButton } from '../../../components/small/Buttons';
import GlassCard from '../../../components/small/GlassCard.tsx';
import TableSearch from '../../../components/_form/TableSearch.jsx';
import { Nav } from '../../../utils';
import { useTranslation } from 'react-i18next';
import useLoader from '../../../components/helpers/UseLoader.tsx';
import useTableLogic from '../../../components/helpers/UseTableLogic.tsx';
import DashboardLayout from '../../../components/DashboardLayout.tsx';
import FmTextField from '../../../components/_mui/FmTextField.tsx';
import { toast } from 'react-toastify';
import useCommonFetchApi from '../../../components/helpers/useCommonFetchApi.tsx';
import FmAutoComplete from '../../../components/_mui/FmAutoComplete.tsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { supplierItemSchema } from '../../../utils/CommonvalidationSchemas.tsx';

const SupplierItems = (props) => {
  const { type } = props;
  const [openCreate, setOpenCreate] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [loading1, setLoader] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const {
    createLoading,
    editLoading,
    startCreateLoading,
    stopCreateLoading,
    startEditLoading,
    stopEditLoading,
  } = useLoader();

  const {
    rows,
    pageSize,
    pageNumber,
    setSearchKeyword,
    isLoading,
    tableRecordCounts,
    searchKeyword,
    handlePaginationModelChange,
    handleSortModelChange,
    fetchData,
  } = useTableLogic('supplieritems');

  const crud = new Crud_Service();
  const { handleSubmit, setValue, control, reset } = useForm({
    resolver: yupResolver(supplierItemSchema),
    mode: 'onChange',
  });
  const { t } = useTranslation();

  const handleSubmitBuilding = async (values) => {
    startCreateLoading();
    await crud.create('supplieritems', values, (_err, res) => {
      if (res?.status === 201) {
        stopCreateLoading();
        setOpenCreate(false);
        toast.success('Supplier Items created successfully');
        fetchData();
      } else {
        stopCreateLoading();
        setOpenCreate(true);
        toast.error(t('something_Went_Wrong!'));
      }
    });
  };

  const { data: taxCodes } = useCommonFetchApi('taxcodes');
  const { data: suppliers } = useCommonFetchApi('suppliers');
  useCommonFetchApi('contracts');
  const { data: items } = useCommonFetchApi('item');
  useCommonFetchApi('supplieritems');

  const handleCreateClickOpen = () => {
    reset();
    setOpenCreate(true);
  };

  const handleCreateClose = async () => {
    reset();
    setOpenCreate(false);
  };

  const handleViewClickOpen = async (props) => {
    setLoader(true);
    setOpenView(true);
    await crud.getSingle(
      'supplieritems',
      props?.supplierItemId,
      (_err, res) => {
        if (res?.status === 200) {
          setSelectedRow(res?.data);
          setLoader(false);
        } else {
          toast.error(t('something_Went_Wrong!'));
        }
      }
    );
  };

  const handleViewClose = () => {
    setOpenView(false);
  };

  const handleEditOpen = async (props) => {
    setOpenEdit(true);
    setLoader(true);

    await crud.getSingle(
      'supplieritems',
      props?.supplierItemId,
      (_err, res) => {
        if (res?.status === 200) {
          Object.entries({
            ...res?.data,
          }).forEach(([key, value]) => {
            setValue(key, value);
          });
          setSelectedRow(res?.data);
          setLoader(false);
        } else {
          toast.error(t('something_Went_Wrong!'));
          setLoader(true);
        }
      }
    );
  };

  const handleEditBuilding = async (values) => {
    startEditLoading();
    await crud.update(
      'supplieritems',
      values?.supplierItemId,
      values,
      (_err, res) => {
        if (res?.status === 200) {
          stopEditLoading();
          setOpenEdit(false);
          toast.success('Supplier Items updated successfully');
          fetchData();
        } else {
          stopEditLoading();
          setOpenEdit(true);
          toast.error(t('something_Went_Wrong!'));
        }
      }
    );
  };

  const handleDelete = async (props) => {
    await crud.remove('supplieritems', props?.supplierItemId, (_err, res) => {
      if (res?.status === 204) {
        toast.success('Supplier Items deleted successfully');
        fetchData();
      } else {
        toast.error(t('something_Went_Wrong!'));
      }
    });
  };

  const counters = [
    {
      name: 'All',
      count: tableRecordCounts?.total || 0,
    },
    {
      name: 'Active',
      count: tableRecordCounts?.active || 0,
    },
    {
      name: 'Deactivated',
      count: tableRecordCounts?.inActive || 0,
    },
  ];

  const ActionData = [
    {
      name: 'View',
      icon: <Icon icon='solar:eye-linear' />,
      onClick: (props, ev) => handleViewClickOpen(props, ev),
    },
    {
      name: 'Edit',
      icon: <Icon icon='solar:pen-new-round-linear' />,
      onClick: (props) => handleEditOpen(props),
    },
    {
      name: 'Delete',
      icon: <Icon icon='solar:trash-bin-2-linear' />,
      onClick: (props) => handleDelete(props),
      danger: true,
    },
  ];

  const columns = [
    {
      field: 'action',
      headerName: '',
      width: 140,
      sortable: false,
      disableClickEventBubbling: false,
      renderCell: (params) => {
        return (
          <TableActionButtons
            Actions={ActionData?.map((action) => ({
              ...action,
              onClick: () => action.onClick(params.row),
            }))}
            selectedRow={params.row}
          />
        );
      },
    },
    {
      field: 'supplierName',
      headerName: 'Supplier Name',
      flex: 1,
    },
    {
      field: 'supplierItemCode',
      headerName: 'Supplier Item Code',
      flex: 1,
    },
    {
      field: 'itemName',
      headerName: 'Item Name',
      flex: 1,
    },
    {
      field: 'totalPrice',
      headerName: 'Total Price',
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => {
        return (
          <StatusBadge
            title={params.value === 2 ? 'Active' : 'Inactive'}
            type={params.value === 2 ? 'green' : 'red'}
          />
        );
      },
    },
  ];

  return (
    <DashboardLayout
      title='Supplier Items'
      actionButtons={
        <>
          <PageHeader counters={counters} />
        </>
      }
      hasSubmenu
      menu={
        type === 'presales'
          ? Nav[4].child[11].children
          : Nav[6].child[3].children
      }
      parentMenu={type === 'presales' ? null : Nav[6].child[3].name}
    >
      {
        <GlassCard>
          <Box sx={{ height: 'calc(100vh - 200px)' }}>
            <DataTable
              rows={rows}
              columns={columns}
              loading={isLoading}
              getRowClassName={(params) =>
                `${params.row.status === 0 && 'bg-red-50'}`
              }
              sortingMode='server'
              paginationMode='server'
              onPaginationModelChange={(model) =>
                handlePaginationModelChange(model)
              }
              onSortModelChange={(model) => handleSortModelChange(model)}
              page={pageNumber - 1}
              pageSize={pageSize}
              rowCount={tableRecordCounts?.total}
              slots={{
                toolbar: () => (
                  <Box
                    className='p-2 w-full flex justify-between items-center'
                    sx={{
                      borderBottom: '1px solid',
                      borderColor: 'border.main',
                    }}
                  >
                    <Box sx={{ maxWidth: '250px' }}>
                      <TableSearch
                        placeholder='Search'
                        fullWidth
                        setSearchKeyword={setSearchKeyword}
                        searchValue={searchKeyword}
                      />
                    </Box>
                    <CreateButton name='New' onClick={handleCreateClickOpen} />
                  </Box>
                ),
              }}
            />
          </Box>
        </GlassCard>
      }

      {/* create  */}
      <MuiDialogOne
        title='Create'
        open={openCreate}
        onClose={handleCreateClose}
      >
        <form onSubmit={handleSubmit(handleSubmitBuilding)}>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <FmAutoComplete
                name='supplierId'
                control={control}
                options={suppliers}
                label='Suppliers'
                displayField='officialSupplierName'
                optionFields={['officialSupplierName']}
                valueKey='supplierId'
              />
            </Grid>
            <Grid item md={6}>
              <FmAutoComplete
                name='itemId'
                control={control}
                options={items}
                label='Item'
                displayField='itemName'
                optionFields={['itemName']}
                valueKey='itemId'
              />
            </Grid>
            <Grid item md={6}>
              <FmTextField
                name='description'
                control={control}
                label='Description'
                rules={{
                  required: 'Description is required',
                }}
              />
            </Grid>
            <Grid item md={6}>
              <FmTextField name='notes' control={control} label='Notes' />
            </Grid>
            <Grid item md={6}>
              <FmTextField name='sku' control={control} label='SKU' />
            </Grid>
            <Grid item md={6}>
              <FmTextField
                name='unitPrice'
                control={control}
                label='Unit Price'
                type='number'
                min
                rules={{
                  required: 'Unit Price is required',
                }}
              />
            </Grid>
            <Grid item md={6}>
              <FmTextField
                name='quantity'
                control={control}
                label='Quantity'
                rules={{
                  required: 'Quantity is required',
                }}
              />
            </Grid>
            <Grid item md={6}>
              <FmAutoComplete
                name='taxCodeId'
                control={control}
                options={taxCodes}
                label='Tax Code'
                displayField='taxCodeName'
                optionFields={['taxCodeName']}
                valueKey='taxCodeId'
              />
            </Grid>

            <Grid item md={6}>
              <FmTextField
                name='discountPercent'
                control={control}
                type='number'
                label='Discount (%)'
              />
            </Grid>
            <Grid item md={6}>
              <FmTextField
                name='totalPrice'
                control={control}
                type='number'
                label='Total Price'
              />
            </Grid>

            <Grid item md={12}>
              <ActionButtons
                onSubmit={handleSubmit(handleSubmitBuilding)}
                onCancel={handleCreateClose}
                onReset={reset}
                submitLoading={createLoading}
                cancelLoading={false}
              />
            </Grid>
          </Grid>
        </form>
      </MuiDialogOne>

      {/* view */}
      <MuiDialogOne
        title='View Details'
        open={openView}
        onClose={handleViewClose}
      >
        {loading1 ? (
          <CommonLoader />
        ) : (
          <Grid container spacing={2}>
            <Grid item md={6}>
              <ViewField
                label='Supplier Item Code'
                title={selectedRow?.supplierItemCode}
              />
            </Grid>
            <Grid item md={6}>
              <ViewField
                label='Supplier Name'
                title={selectedRow?.supplierName}
              />
            </Grid>{' '}
            <Grid item md={6}>
              <ViewField label='Item Name' title={selectedRow?.itemName} />
            </Grid>
            <Grid item md={6}>
              <ViewField label='Description' title={selectedRow?.description} />
            </Grid>
            <Grid item md={6}>
              <ViewField label='Notes' title={selectedRow?.notes} />
            </Grid>
            <Grid item md={6}>
              <ViewField label='SKU' title={selectedRow?.sku} />
            </Grid>
            <Grid item md={6}>
              <ViewField label='Unit Price' title={selectedRow?.unitPrice} />
            </Grid>
            <Grid item md={6}>
              <ViewField label='Quantity' title={selectedRow?.quantity} />
            </Grid>
            <Grid item md={6}>
              <ViewField
                label='Tax Code Name'
                title={selectedRow?.taxCodeName}
              />
            </Grid>
            <Grid item md={6}>
              <ViewField
                label='Tax Code Value'
                title={selectedRow?.taxCodeValue}
              />
            </Grid>
            <Grid item md={6}>
              <ViewField
                label='Discount'
                title={selectedRow?.discountPercent}
              />
            </Grid>
            <Grid item md={6}>
              <ViewField label='Total Price' title={selectedRow?.totalPrice} />
            </Grid>
            <Grid item md={6}>
              <ViewField label='Status' title={selectedRow?.statusName} />
            </Grid>
          </Grid>
        )}
      </MuiDialogOne>

      {/*EDIT*/}
      <MuiDialogOne
        title='Edit'
        open={openEdit}
        onClose={() => setOpenEdit(false)}
      >
        {loading1 ? (
          <CommonLoader />
        ) : (
          <form onSubmit={handleSubmit(handleEditBuilding)}>
            <Grid container spacing={3}>
              <Grid item md={6}>
                <FmAutoComplete
                  name='supplierId'
                  control={control}
                  options={suppliers}
                  label='Suppliers'
                  displayField='officialSupplierName'
                  optionFields={['officialSupplierName']}
                  valueKey='supplierId'
                />
              </Grid>
              <Grid item md={6}>
                <FmAutoComplete
                  name='itemId'
                  control={control}
                  options={items}
                  label='Item'
                  displayField='itemName'
                  optionFields={['itemName']}
                  valueKey='itemId'
                />
              </Grid>

              <Grid item md={6}>
                <FmTextField
                  name='description'
                  control={control}
                  label='Description'
                  rules={{
                    required: 'Description is required',
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <FmTextField name='notes' control={control} label='Notes' />
              </Grid>
              <Grid item md={6}>
                <FmTextField name='sku' control={control} label='SKU' />
              </Grid>
              <Grid item md={6}>
                <FmTextField
                  name='unitPrice'
                  control={control}
                  label='Unit Price'
                  type='number'
                  min
                  rules={{
                    required: 'Unit Price is required',
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <FmTextField
                  name='quantity'
                  control={control}
                  label='Quantity'
                  rules={{
                    required: 'Quantity is required',
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <FmAutoComplete
                  name='taxCodeId'
                  control={control}
                  options={taxCodes}
                  label='Tax Code'
                  displayField='taxCodeName'
                  optionFields={['taxCodeName']}
                  valueKey='taxCodeId'
                />
              </Grid>

              <Grid item md={6}>
                <FmTextField
                  name='discountPercent'
                  control={control}
                  type='number'
                  label='Discount (%)'
                />
              </Grid>
              <Grid item md={6}>
                <FmTextField
                  name='totalPrice'
                  control={control}
                  type='number'
                  label='Total Price'
                />
              </Grid>
              <Grid item md={12}>
                <ActionButtons
                  onSubmit={handleSubmit(handleEditBuilding)}
                  onCancel={() => setOpenEdit(false)}
                  submitLoading={editLoading}
                  onReset={reset}
                  cancelLoading={false}
                  submitText='Update'
                />
              </Grid>
            </Grid>
          </form>
        )}
      </MuiDialogOne>
    </DashboardLayout>
  );
};

export default SupplierItems;
