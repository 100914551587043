import { lazy, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { SettingsProvider } from './utils/SettingsContext.jsx';
import runOneSignal from './utils/OneSignal.tsx';
import ExampleWithProviders from './pages/Presales/sample/ExampleDataGrid.jsx';
import SuppliersSpoc from './pages/Presales/Suppliers/SupplierSpoc.tsx';
import TenderEnquiryTypes from './pages/Presales/AllMasters/TenderEnquiryTypes.tsx';
import TenderProjectTypes from './pages/Presales/AllMasters/TenderProjectTypes.tsx';
import TenderFormats from './pages/Presales/AllMasters/TenderFormat.tsx';
import CountryMaster from './pages/Presales/AllMasters/Country.tsx';
import SupplierTypes from './pages/Presales/AllMasters/SupplierTypes.tsx';
import TenderFollowUpTypesMasters from './pages/Presales/AllMasters/TenderFollowUpTypes.tsx';
import TenderStatusMasters from './pages/Presales/AllMasters/TenderStatus.tsx';
import ContactModesMasters from './pages/Presales/AllMasters/ContactModes.tsx';
import CompanyPolicies from './pages/Pr/AllMasters/CompanyPolicies.jsx';
import CompanyPolicyTypes from './pages/Pr/AllMasters/CompanyPolicyTypes.jsx';
import EndUsers from './pages/Pr/AllMasters/EndUsers.jsx';
import TypeOfProducts from './pages/Pr/AllMasters/TypeOfProducts.jsx';
import MedicalInsuranceLocation from './pages/Pr/AllMasters/MedicalInsuranceLocation.jsx';
import MedicalInsuranceStatus from './pages/Pr/AllMasters/MedicalInsuranceStatus.jsx';
import MedicalInsuranceAgeBands from './pages/Pr/AllMasters/MedicalInsuranceAgeBands.jsx';
import MedicalInsurancePolicies from './pages/Pr/AllMasters/CompanyInsurancePolicies.jsx';
import SupplierItems from './pages/Procurement/Masters/SupplierItems.jsx';
import PremiseLocations from './pages/Pr/AllMasters/PremiseLocations.jsx';
import PolicyPremium from './pages/Pr/AllMasters/PolicyPremium.jsx';
import MedicalInsuranceSalaryBands from './pages/Pr/AllMasters/MedicalInsuranceSalaryBand.jsx';
import CompanyCertificate from './pages/Hrms/EmployeeMaster/CompanyCertificate.jsx';
import CreateCompanyCertificates from './pages/Hrms/EmployeeMaster/CreateCompanyCertificate.jsx';
import MedicalInsuranceDependencies from './pages/Pr/AllMasters/MedicalInsuranceDependencies.jsx';
import MedicalInsurancePremisePurpose from './pages/Pr/AllMasters/MedicalInsurancePremisePurpose.jsx';
import MedicalInsurancePremiseTypes from './pages/Pr/AllMasters/MedicalInsurancePremiseTypes.jsx';
import UniformSizes from './pages/Pr/AllMasters/UniformSizes.jsx';
import UniformTypes from './pages/Pr/AllMasters/UniformTypes.jsx';
import Insurer from './pages/Pr/AllMasters/Insurer.jsx';
import StoreLocations from './pages/Cafm/inventory/storeLocations/StoreLocations.tsx';
import EditCompanyCertificates from './pages/Hrms/EmployeeMaster/EditCompanyCertificate.jsx';
const Login = lazy(() => import('./pages/Login'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const ErrorPage = lazy(() => import('./pages/ErrorPage'));

const UnderConstruction = lazy(() =>
  import('./pages/ErrorPage/UnderConstruction.tsx')
);
const EmployeeCreate = lazy(() =>
  import('./pages/Hrms/EmployeeMaster/Create/Create')
);
const FinalSettlement = lazy(() =>
  import('./pages/Hrms/FinalSettlements/FinalSettlement.tsx')
);
const LeaveTransaction = lazy(() =>
  import('./pages/Hrms/EmployeeMaster/LeaveTransaction')
);
const LeaveJoin = lazy(() =>
  import('./pages/Hrms/EmployeeMaster/LeaveJoin/LeaveJoin.tsx')
);
const EmpAllMasters = lazy(() => import('./pages/Hrms/AllMasters/index.jsx'));
const DepartmentMasters = lazy(() =>
  import('./pages/Hrms/AllMasters/DepartmentMasters.tsx')
);
const CreateTrainingAndEvents = lazy(() =>
  import('./pages/Hrms/TrainingAndEvents/TrainingAndEventsCreate.tsx')
);
const AttendanceCalculations = lazy(() =>
  import('./pages/Hrms/EmployeeMaster/AttendanceCalculations.tsx')
);
const ShiftTimeMaster = lazy(() =>
  import('./pages/Hrms/ShiftTimeMaster/ShiftTime.jsx')
);
const AttendanceSummary = lazy(() =>
  import('./pages/Hrms/EmployeeMaster/AttendanceSummary')
);
const ExpenseTypePr = lazy(() =>
  import('./pages/Pr/AllMasters/ExpenseType.tsx')
);
const TrainingAndEvents = lazy(() =>
  import('./pages/Hrms/TrainingAndEvents/TrainingAndEvents.tsx')
);
const Certificates = lazy(() =>
  import('./pages/Pr/certificates/Certificates.tsx')
);
const DocumentManagement = lazy(() =>
  import('./pages/Hrms/DocumentManagement/DocumentManagement.tsx')
);
const HRMSEmployeeMaster = lazy(() =>
  import('./pages/Hrms/EmployeeMaster/HRMSEmployeeMaster.jsx')
);
const LeaveRequest = lazy(() =>
  import('./pages/Hrms/EmployeeMaster/LeaveRequest/LeaveRequest.tsx')
);
const EmployementCancellation = lazy(() =>
  import('./pages/Hrms/EmployementCancellation/EmployementCancellation.tsx')
);
const CashAccountMgmt = lazy(() =>
  import('./pages/Pr/CashAccountManagement/CashAccountMgmt.tsx')
);
const TransactionManagement = lazy(() =>
  import('./pages/Pr/TransactionManagement/TransactionManagement.tsx')
);
const RegionMasters = lazy(() =>
  import('./pages/Hrms/AllMasters/RegionMasters.tsx')
);
const EmployeeStudies = lazy(() => import('./pages/Hrms/EmployeeStudies.tsx'));
const DesignationMasters = lazy(() =>
  import('./pages/Hrms/AllMasters/DesignationMasters.tsx')
);
const LocationMasters = lazy(() =>
  import('./pages/Hrms/AllMasters/LocationMasters.tsx')
);
const NationalityMasters = lazy(() =>
  import('./pages/Hrms/AllMasters/NationalityMasters.tsx')
);
const ContractTypeMasters = lazy(() =>
  import('./pages/Hrms/AllMasters/ContractTypeMasters.tsx')
);
const BloodGroupMasters = lazy(() =>
  import('./pages/Hrms/AllMasters/BloodGroupMasters.tsx')
);
const CertificateTypeMasters = lazy(() =>
  import('./pages/Hrms/AllMasters/CertificateTypeMasters.tsx')
);
const CancellationTypeMasters = lazy(() =>
  import('./pages/Hrms/AllMasters/CancellationTypeMasters.tsx')
);
const DocumentTypeMasters = lazy(() =>
  import('./pages/Hrms/AllMasters/DocumentTypeMasters.tsx')
);
const TrainingTransaction = lazy(() =>
  import('./pages/Hrms/TraningAndTranaction/TrainingTransaction.tsx')
);
const VehicleMaster = lazy(() =>
  import('./pages/Pr/VehicleMaster/VehicleMaster.tsx')
);
const Enquiry = lazy(() =>
  import('./pages/Presales/EnquiryMaster/Enquiry.tsx')
);
const Candidates = lazy(() => import('./pages/Pr/Candidates/Candidates.tsx'));
const JobRequisition = lazy(() =>
  import('./pages/Hrms/JobRequisition/JobRequisition.tsx')
);
const JobRecruitments = lazy(() =>
  import('./pages/Hrms/JobRecruitments/JobRecruitments.tsx')
);
const CreatePassportRequest = lazy(() =>
  import('./pages/Hrms/PassportRequest/PassportRequestCreate.tsx')
);
const CreateTraningAndTransaction = lazy(() =>
  import('./pages/Hrms/TraningAndTranaction/TraningTransactionCreate.tsx')
);
const EditTraningAndTransaction = lazy(() =>
  import('./pages/Hrms/TraningAndTranaction/TraningTransactionEdit.tsx')
);
const EditPassportRequest = lazy(() =>
  import('./pages/Hrms/PassportRequest/PassportRequestEdit.tsx')
);
const PassportRequest = lazy(() =>
  import('./pages/Hrms/PassportRequest/PassportRequest.tsx')
);
const CreateCertificates = lazy(() =>
  import('./pages/Pr/certificates/CertificatesCreate.tsx')
);
const EditCertificates = lazy(() =>
  import('./pages/Pr/certificates/CertificatesEdit.tsx')
);
const CreateCandidates = lazy(() =>
  import('./pages/Pr/Candidates/CandidateCreate.tsx')
);
const EditCandidates = lazy(() =>
  import('./pages/Pr/Candidates/CandidateEdit.tsx')
);
const CreateVehicleMaster = lazy(() =>
  import('./pages/Pr/VehicleMaster/VehicleMasterCreate.tsx')
);
const EditVehicleMaster = lazy(() =>
  import('./pages/Pr/VehicleMaster/VehicleMasterEdit.tsx')
);
const EssLogin = lazy(() => import('./pages/Login/EssLogin.tsx'));
const EssDashboard = lazy(() => import('./pages/Dashboard/EssDashboard.tsx'));
const CreateJobRequisition = lazy(() =>
  import('./pages/Hrms/JobRequisition/JobRequisitionCreate.tsx')
);
const EditJobRequisition = lazy(() =>
  import('./pages/Hrms/JobRequisition/JobRequisitionEdit.tsx')
);
const CreateJobRecruitment = lazy(() =>
  import('./pages/Hrms/JobRecruitments/JobReCruitmentsCreate.tsx')
);
const EditJobRecruitment = lazy(() =>
  import('./pages/Hrms/JobRecruitments/JobReCruitmentsEdit.jsx')
);
const SettingsForm = lazy(() => import('./utils/SettingsForm.jsx'));
const CreateFinalSettlement = lazy(() =>
  import('./pages/Hrms/FinalSettlements/FinalSettlementCreate.tsx')
);
const EditFinalSettlement = lazy(() =>
  import('./pages/Hrms/FinalSettlements/FinalSettlementEdit.tsx')
);
const WpsMasters = lazy(() => import('./pages/Hrms/AllMasters/WpsMasters.tsx'));
const EditTrainingAndEvents = lazy(() =>
  import('./pages/Hrms/TrainingAndEvents/TrainingAndEventsEdit.tsx')
);
const CreateEnquiry = lazy(() =>
  import('./pages/Presales/EnquiryMaster/CreateEnquiry.tsx')
);
const EditEnquiry = lazy(() =>
  import('./pages/Presales/EnquiryMaster/EditEnquiry.tsx')
);
const CreateLeaveRequest = lazy(() =>
  import('./pages/Hrms/EmployeeMaster/LeaveRequest/CreateLeaveRequest.tsx')
);
const EditLeaveRequest = lazy(() =>
  import('./pages/Hrms/EmployeeMaster/LeaveRequest/EditLeaveRequest.tsx')
);
const PassportAndVisa = lazy(() =>
  import('./pages/Pr/PassportAndVisa/PassportAndVisa.tsx')
);
const DocumentsList = lazy(() =>
  import('./pages/Fm/GeneralDocuments/DocumentsList.jsx')
);
const CreateDocuments = lazy(() =>
  import('./pages/Fm/GeneralDocuments/CreateDocuments.tsx')
);
const PositionsMaster = lazy(() =>
  import('./pages/Hrms/AllMasters/PositionsMaster.tsx')
);
const LeaveJoinCreate = lazy(() =>
  import('./pages/Hrms/EmployeeMaster/LeaveJoin/LeaveJoinCreate.tsx')
);
const CreateCashAccountManagement = lazy(() =>
  import('./pages/Pr/CashAccountManagement/CreateCashAccount.tsx')
);
const EditCashAccountManagement = lazy(() =>
  import('./pages/Pr/CashAccountManagement/EditCashAccount.jsx')
);
const CompanyMasters = lazy(() =>
  import('./pages/Hrms/AllMasters/CompanyMasters.tsx')
);
const DepartmentShifts = lazy(() =>
  import('./pages/Hrms/DepartmentShifts/DepatmentShifts.tsx')
);
const CreateEmpCancellation = lazy(() =>
  import('./pages/Hrms/EmployementCancellation/CreateEmpCancellation.tsx')
);
const EditEmpCancellation = lazy(() =>
  import('./pages/Hrms/EmployementCancellation/EditEmpCancellation.tsx')
);
const ApprovalNode = lazy(() =>
  import('./pages/Fm/ApprovalNode/ApprovalNode.tsx')
);
const HolidaysList = lazy(() => import('./pages/Fm/Holidays/HolidaysList.tsx'));
const CreateHolidays = lazy(() =>
  import('./pages/Fm/Holidays/CreateHolidays.tsx')
);
const LeaveJoinEdit = lazy(() =>
  import('./pages/Hrms/EmployeeMaster/LeaveJoin/LeaveJoinEdit.tsx')
);
const EditHolidays = lazy(() => import('./pages/Fm/Holidays/EditHoliday.tsx'));
const TenderEnquiry = lazy(() =>
  import('./pages/Presales/TenderEnquiry/TenderEnquiry.tsx')
);
const CreateTenderEnquiry = lazy(() =>
  import('./pages/Presales/TenderEnquiry/CreateTenderEnquiry.tsx')
);
const EditTenderEnquiry = lazy(() =>
  import('./pages/Presales/TenderEnquiry/EditTenderEnquiry.tsx')
);
const DocumentTypeCategoryMasters = lazy(() =>
  import('./pages/Hrms/AllMasters/DocumentTypeCategoryMasters.tsx')
);
const ExperienceTypeMasters = lazy(() =>
  import('./pages/Hrms/AllMasters/ExperienceTypeMasters.tsx')
);
const TenderInspectionChecklist = lazy(() =>
  import(
    './pages/Presales/TenderInspectionChecklist/TenderInspectionChecklist.tsx'
  )
);
const CreateTenderInspectionChecklist = lazy(() =>
  import(
    './pages/Presales/TenderInspectionChecklist/CreateTenderInspectionChecklist.tsx'
  )
);
const CreateEstimationSheetForAMC = lazy(() =>
  import(
    './pages/Presales/EstimationSheetForAMC/CreateEstimationSheetForAMC.tsx'
  )
);
const EstimationSheetForAMC = lazy(() =>
  import('./pages/Presales/EstimationSheetForAMC/EstimationSheetForAMC.tsx')
);
const EditEstimationSheet = lazy(() =>
  import('./pages/Presales/EstimationSheetForAMC/EditEstimationSheet.tsx')
);
const HRMSEmployeeMasterDashboard = lazy(() =>
  import('./pages/Hrms/EmployeeMaster/HRMSEmployeeMasterDashboard.tsx')
);
const CalendarBooking = lazy(() =>
  import('./pages/Fm/CalendarBooking/CalendarBooking.tsx')
);
const CardTypes = lazy(() => import('./pages/Pr/AllMasters/CardTypes.jsx'));
const ExampleComponent = lazy(() => import('./ExampleComponent.jsx'));
const SettingsForms = lazy(() => import('./utils/SettingsForm.js'));
const CreatePassportAndVisa = lazy(() =>
  import('./pages/Pr/PassportAndVisa/CreatePassportAndVisa.tsx')
);
const EditPassportAndVisa = lazy(() =>
  import('./pages/Pr/PassportAndVisa/EditPassportAndVisa.tsx')
);
const CreateContracts = lazy(() =>
  import('./pages/Presales/Contract/CreateContracts.tsx')
);
const ManageContracts = lazy(() =>
  import('./pages/Presales/Contract/ManageContracts.tsx')
);
const EditContracts = lazy(() =>
  import('./pages/Presales/Contract/EditContracts.tsx')
);

const ViewContracts = lazy(() =>
  import('./pages/Presales/Contract/ViewContract/ViewContracts.tsx')
);

const Suppliers = lazy(() =>
  import('./pages/Presales/Suppliers/Suppliers.tsx')
);
const CreateSuppliers = lazy(() =>
  import('./pages/Presales/Suppliers/CreateSuppliers.tsx')
);
const EditSuppliers = lazy(() =>
  import('./pages/Presales/Suppliers/EditSuppliers.tsx')
);
const CafmTicket = lazy(() => import('./pages/Cafm/Ticket/CafmTicket.tsx'));
const CreateTicket = lazy(() => import('./pages/Cafm/Ticket/CreateTicket.tsx'));
const EmployeeUpdate = lazy(() =>
  import('./pages/Hrms/EmployeeMaster/EmployeeUpdate/EmployeeUpdate.tsx')
);
const Clients = lazy(() => import('./pages/Presales/Clients/Clients.tsx'));
const Createclient = lazy(() =>
  import('./pages/Presales/Clients/CreateClients.tsx')
);
const EditClients = lazy(() =>
  import('./pages/Presales/Clients/EditClients.tsx')
);
const MaterialRequestList = lazy(() =>
  import('./pages/Procurement/MaterialRequest/MaterialRequestList.tsx')
);
const CreateMaterialRequest = lazy(() =>
  import('./pages/Procurement/MaterialRequest/CreateMaterialRequest.tsx')
);
const ViewMaterialRequest = lazy(() =>
  import('./pages/Procurement/MaterialRequest/ViewMaterialRequest.tsx')
);
const Projects = lazy(() => import('./pages/Presales/Projects/Projects.tsx'));
const CreateProjects = lazy(() =>
  import('./pages/Presales/Projects/CreateProjects.tsx')
);

const ViewProjects = lazy(() =>
  import('./pages/Presales/Projects/ViewProjects/ViewProjects.tsx')
);

const EditProjects = lazy(() =>
  import('./pages/Presales/Projects/EditProjects.tsx')
);
const TemplateList = lazy(() =>
  import('./pages/QuickLinks/Template/TemplateList.tsx')
);
const CreateTemplate = lazy(() =>
  import('./pages/QuickLinks/Template/CreateTemplate.tsx')
);
const ViewEnquiry = lazy(() =>
  import('./pages/Presales/EnquiryMaster/ViewEnquiry.jsx')
);
const EstimationSheetForMEP = lazy(() =>
  import('./pages/Presales/EstimationSheetForMEP/EstimationSheetsMEP.tsx')
);
const CreateEstimationSheetForMEP = lazy(() =>
  import('./pages/Presales/EstimationSheetForMEP/CreateEstimationMEP.tsx')
);
const EditEstimationSheetMEP = lazy(() =>
  import('./pages/Presales/EstimationSheetForMEP/EditEstimationMEP.tsx')
);
const ViewEstimationMEP = lazy(() =>
  import('./pages/Presales/EstimationSheetForMEP/ViewEstimationMEP.tsx')
);
const EditTenderInspectionChecklist = lazy(() =>
  import(
    './pages/Presales/TenderInspectionChecklist/EditTenderInspectionChecklist.tsx'
  )
);
const ViewTenderCheckListDetail = lazy(() =>
  import(
    './pages/Presales/TenderInspectionChecklist/ViewTenderChecklistDetail.tsx'
  )
);
const ViewSuppliers = lazy(() =>
  import('./pages/Presales/Suppliers/ViewSuppliers.tsx')
);
const QuestionsBuilderList = lazy(() =>
  import('./pages/QuickLinks/QuestionsBuilder/QuestionsBuilderList.tsx')
);
const CreateQuestionsBuilder = lazy(() =>
  import('./pages/QuickLinks/QuestionsBuilder/CreateQuestionsBuilder.tsx')
);
const ProposalList = lazy(() =>
  import('./pages/Presales/ProposalLineItemsMaster/List.tsx')
);
const ProposalCreate = lazy(() =>
  import('./pages/Presales/ProposalLineItemsMaster/Create.tsx')
);
const ProposalView = lazy(() =>
  import('./pages/Presales/ProposalLineItemsMaster/View.tsx')
);
const Building = lazy(() => import('./pages/Procurement/Masters/Building.tsx'));
const Levels = lazy(() => import('./pages/Procurement/Masters/Levels.tsx'));
const LevelUnits = lazy(() =>
  import('./pages/Procurement/Masters/LevelUnits.tsx')
);
const CostCenters = lazy(() =>
  import('./pages/Procurement/Masters/CostCenters.tsx')
);
const ItemManagementList = lazy(() =>
  import('./pages/Procurement/ItemManagement/ItemManagementList.tsx')
);
/////Request Quote MAnagement
const RequestQuoteManagement = lazy(() =>
  import(
    './pages/Procurement/RequestQuoteManagement/RequestQuoteManagement.tsx'
  )
);
const CreateRequestQuote = lazy(() =>
  import('./pages/Procurement/RequestQuoteManagement/CreateQuoteRequest.tsx')
);
/////
const PurchaseOrderViewList = lazy(() =>
  import('./pages/Procurement/PurchaseOrderView/PurchaseOrderViewList.tsx')
);
const CreatePurchaseOrderView = lazy(() =>
  import('./pages/Procurement/PurchaseOrderView/CreatePurchaseOrderView.tsx')
);
const PurchaseOrderPreview = lazy(() =>
  import('./pages/Procurement/PurchaseOrderView/PurchaseOrderPreview.tsx')
);
const GoodsReceivedNoteList = lazy(() =>
  import('./pages/Procurement/GoodsReceivedNote/GoodsReceivedNoteList.tsx')
);
const PayGroupMaster = lazy(() =>
  import('./pages/Hrms/AllMasters/PayGroupMasters.tsx')
);
const TinyMCEEditor = lazy(() => import('./pages/TextEditor.jsx'));
const CostCenterMasters = lazy(() =>
  import('./pages/Hrms/AllMasters/CostCenterMasters.tsx')
);
const PurchaseOrderViewSendLPO = lazy(() =>
  import('./pages/Procurement/PurchaseOrderView/PurchaseOrderViewSendLPO.tsx')
);
const LeaveConfiguration = lazy(() =>
  import('./pages/Hrms/LeaveConfiguration.jsx')
);
const QuotaEmployeesList = lazy(() =>
  import('./pages/Pr/QuotaEmployeesSummary/QuotaEmployeesList.tsx')
);
const ManpowerRateCardList = lazy(() =>
  import('./pages/Hrms/ManpowerRatecard/ManpowerRateCardList.tsx')
);
const PRDashboard = lazy(() => import('./pages/Dashboard/PRDashboard.tsx'));
const CreateTransactionManagement = lazy(() =>
  import('./pages/Pr/TransactionManagement/CreateTransactionManagement.tsx')
);
const EditTransactionManagement = lazy(() =>
  import('./pages/Pr/TransactionManagement/EditTransactionManagement.tsx')
);
const ViewTransactionManagement = lazy(() =>
  import('./pages/Pr/TransactionManagement/ViewTransactionManagement.tsx')
);
const InterviewAssessmentForm = lazy(() =>
  import('./pages/Pr/Candidates/InterviewAssessmentForm.tsx')
);
const CandidateConfirmation = lazy(() =>
  import('./pages/Pr/Candidates/CandidateConfirmation.tsx')
);
const EmployeeSalaryCalculations = lazy(() =>
  import('./pages/Hrms/EmployeeMaster/EmployeeSalaryCalculations.tsx')
);
const DocumentCollection = lazy(() =>
  import('./pages/Pr/Candidates/DocumentCollection.tsx')
);

const GoodsReceivedNotesCreate = lazy(() =>
  import('./pages/Procurement/GoodsReceivedNote/GoodsReceivedNotesCreate.tsx')
);

const ProjectDivisionList = lazy(() =>
  import('./pages/Cafm/ProjectDivision/ProjectDivsion.tsx')
);

const ManageAssigneeList = lazy(() =>
  import('./pages/Cafm/ProjectDivision/ManageAssignee/ManageAssigneeList.tsx')
);

const ProjectPlanList = lazy(() =>
  import('./pages/Cafm/ProjectDivision/ProjectPlan/ProjectPlanList.tsx')
);

const ProjectActivityList = lazy(() =>
  import('./pages/Cafm/ProjectDivision/ProjectActivity/ProjectActivityList.tsx')
);

const BoqHeadMasterList = lazy(() =>
  import('./pages/Cafm/AllMaster/BoqHeadMasterList.tsx')
);

const BoqPackageMaster = lazy(() =>
  import('./pages/Cafm/AllMaster/BoqPackageMaster.tsx')
);

const BoqSubHeadMaster = lazy(() =>
  import('./pages/Cafm/AllMaster/BoqSubHead/BoqSubHeadMaster.tsx')
);

const BoqLineItemMaster = lazy(() =>
  import('./pages/Cafm/AllMaster/BoqLineItemMaster.tsx')
);

const ProjectMainCost = lazy(() =>
  import(
    './pages/Cafm/ProjectDivision/ProjectCostTracking/ProjectMainCost/ProjectMainCost.tsx'
  )
);

const BudgetProjectCostTracking = lazy(() =>
  import(
    './pages/Cafm/ProjectDivision/ProjectCostTracking/Budget/BudgetProjectCostTracking.tsx'
  )
);

const ManpowerProjectCostTracking = lazy(() =>
  import(
    './pages/Cafm/ProjectDivision/ProjectCostTracking/Manpower/ManpowerProjectCostTracking.tsx'
  )
);

const PettyCashProjectCostTracking = lazy(() =>
  import(
    './pages/Cafm/ProjectDivision/ProjectCostTracking/Pettycash/PettyCashProjectCostTracking.tsx'
  )
);
//inventory
const InventoryList = lazy(() =>
  import('./pages/Cafm/inventory/InventoryList.tsx')
);
const InventoryView = lazy(() =>
  import('./pages/Cafm/inventory/InventoryView.tsx')
);
const AddInventory = lazy(() =>
  import('./pages/Cafm/inventory/AddInventory.tsx')
);
const AssetHistory = lazy(() =>
  import('./pages/Cafm/AssetHistory/AssetHistoryList.tsx')
);
const AssetHistoryView = lazy(() =>
  import('./pages/Cafm/AssetHistory/View/AssetHistoryView.tsx')
);

const CompanyInsurancePolicyList = lazy(() =>
  import('./pages/Pr/CompanyInsurancePolicy/CompanyInsurancePolicyList.tsx')
);

const ComparisonPolicy = lazy(() =>
  import('./pages/Pr/CompanyInsurancePolicy/ComparisonPolicy.tsx')
);

const CompanyMedicalInsurancePolicy = lazy(() =>
  import(
    './pages/Pr/CompanyMedicalInsurancePolicy/CompanyMedicalInsurancePolicy.tsx'
  )
);

const CreateCompanyMedicalInsurance = lazy(() =>
  import(
    './pages/Pr/CompanyMedicalInsurancePolicy/CreateCompanyMedicalInsurance.tsx'
  )
);

const MIPPremiumCalculator = lazy(() =>
  import('./pages/Pr/CompanyMedicalInsurancePolicy/MIPPremiumCalculator.tsx')
);

const EmployeeWiseMIPCalculator = lazy(() =>
  import(
    './pages/Pr/CompanyMedicalInsurancePolicy/EmployeeWiseMIPCalculator.tsx'
  )
);

const AccommodationList = lazy(() =>
  import('./pages/Pr/Accommodation/AccommodationList.tsx')
);

const UniformRequistionList = lazy(() =>
  import('./pages/Pr/UniformAccessories/UniformRequistionList.tsx')
);

const EventsCalender = lazy(() =>
  import('./pages/Pr/EventsCalendar/EventsCalendar.tsx')
);

const ViewEventsCalendar = lazy(() =>
  import('./pages/Pr/EventsCalendar/View/ViewEventsCalendar.tsx')
);

const PurchaseVoucher = lazy(() =>
  import(
    './pages/Financial/AccountsPayable/PurchaseVoucher/PurchaseVoucher.tsx'
  )
);

const SalesOrder = lazy(() =>
  import('./pages/Financial/AccountsPayable/SalesOrder/SalesOrder.tsx')
);

const SalesRetention = lazy(() =>
  import('./pages/Financial/AccountsPayable/SalesRetention/SalesRetention.tsx')
);

const InspectionReport = lazy(() =>
  import('./pages/Cafm/InspectionReport/InspectionReport.tsx')
);

const ViewInspectionReport = lazy(() =>
  import('./pages/Cafm/InspectionReport/View/ViewInspectionReport.tsx')
);

const IncidentReport = lazy(() =>
  import('./pages/Cafm/IncidentReport/IncidentReport.tsx')
);

const ViewIncident = lazy(() =>
  import('./pages/Cafm/IncidentReport/View/ViewIncident.tsx')
);

const ViewIncidentRemarks = lazy(() =>
  import('./pages/Cafm/IncidentReport/View/ViewRemarks.tsx')
);

const ViewInjuries = lazy(() =>
  import('./pages/Cafm/IncidentReport/View/ViewInjuries.tsx')
);

const SnagReport = lazy(() => import('./pages/Cafm/SnagReport/SnagReport.tsx'));

const SnagTab = lazy(() => import('./pages/Cafm/SnagReport/View/SnagTab.tsx'));

const CreateWorkOrder = lazy(() =>
  import('./pages/Cafm/WorkOrderMaster/CreateWorkOrder/CreateWorkOrder.tsx')
);

const CreateSalesQuotation = lazy(() =>
  import(
    './pages/Cafm/WorkOrderMaster/CreateSalesQuotation/CreateSalesQuotation.tsx'
  )
);

const ViewCreateSalesQuotation = lazy(() =>
  import(
    './pages/Cafm/WorkOrderMaster/CreateSalesQuotation/View/ViewCreateSalesQuotation.tsx'
  )
);

const CreateSalesOrder = lazy(() =>
  import('./pages/Cafm/WorkOrderMaster/CreateSalesOrder/CreateSalesOrder.tsx')
);

const CreateCheckInForm = lazy(() =>
  import('./pages/Cafm/SnagReport/CreateCheckInForm/CreateCheckInForm.tsx')
);

const ViewCreateCheckInForm = lazy(() =>
  import(
    './pages/Cafm/SnagReport/CreateCheckInForm/View/ViewCreateCheckInForm.tsx'
  )
);

const CreateCheckoutView = lazy(() =>
  import(
    './pages/Cafm/SnagReport/CreateCheckOutForm/View/CreateCheckOutView.tsx'
  )
);

const ViewSalesOrder = lazy(() =>
  import(
    './pages/Cafm/WorkOrderMaster/CreateSalesOrder/View/ViewSalesOrder.tsx'
  )
);

const App = () => {
  useEffect(() => {
    runOneSignal();
  });
  return (
    <>
      <SettingsProvider>
        <Router>
          <Routes>
            <Route path='/DataGrid-inline' element={<ExampleWithProviders />} />
            <Route path='*' element={<ErrorPage />} />
            <Route path='/' element={<Login />} />
            <Route path='user-master' element={<SettingsForm />} />
            <Route path='SettingsForms' element={<SettingsForms />} />
            <Route path='ExampleComponent' element={<ExampleComponent />} />
            <Route path='text-editor' element={<TinyMCEEditor />} />
            {/* ------------------FM-------------------- */}
            <Route path='fm'>
              <Route
                index
                element={<Navigate replace to='/fm/general-documents' />}
              />
              <Route path='general-documents'>
                <Route index element={<DocumentsList />} />
                <Route path='create' element={<CreateDocuments />} />
              </Route>
              <Route path='approval-node' element={<ApprovalNode />} />
              <Route path='holidays'>
                <Route index element={<HolidaysList />} />
                <Route path='create' element={<CreateHolidays />} />
                <Route path='edit' element={<EditHolidays />} />
              </Route>
              <Route path='calendar-booking' element={<CalendarBooking />} />
              <Route path='template'>
                <Route index element={<TemplateList />} />
                <Route path='create' element={<CreateTemplate />} />
              </Route>
              <Route path='questions'>
                <Route index element={<QuestionsBuilderList />} />
                <Route path='create' element={<CreateQuestionsBuilder />} />
              </Route>
            </Route>
            {/* -----------------------HRMS------------------------- */}
            <Route path='hrms'>
              <Route
                index
                element={<Navigate replace to='/hrms/dashboard' />}
              />
              <Route path='dashboard' element={<Dashboard />} />
              <Route path='company-certificate'>
                <Route index element={<CompanyCertificate />} />
                <Route path='create' element={<CreateCompanyCertificates />} />
                <Route path='edit/:id' element={<EditCompanyCertificates />} />
              </Route>
              <Route path='employee'>
                <Route
                  index
                  element={<Navigate replace to='/hrms/employee/dashboard' />}
                />
                <Route
                  path='dashboard'
                  element={<HRMSEmployeeMasterDashboard />}
                />
                <Route path='master-list'>
                  <Route index element={<HRMSEmployeeMaster />} />
                  <Route path='create' element={<EmployeeCreate />} />
                  <Route path='edit/:id' element={<EmployeeUpdate />} />
                </Route>
                <Route path='attendance' element={<AttendanceCalculations />} />
                <Route path='leave-request'>
                  <Route index element={<LeaveRequest />} />
                  <Route path='create' element={<CreateLeaveRequest />} />
                  <Route path='edit/:id' element={<EditLeaveRequest />} />
                </Route>
                <Route
                  path='leave-transaction'
                  element={<LeaveTransaction />}
                />

                <Route path='leave-join'>
                  <Route index element={<LeaveJoin />} />
                  <Route path='create' element={<LeaveJoinCreate />} />
                  <Route path='edit/:id' element={<LeaveJoinEdit />} />
                </Route>
                <Route path='passport-request'>
                  <Route index element={<PassportRequest type={'HRMS'} />} />
                  <Route path='create' element={<CreatePassportRequest />} />
                  <Route path='edit/:id' element={<EditPassportRequest />} />
                </Route>

                <Route path='cancellation'>
                  <Route index element={<EmployementCancellation />} />
                  <Route path='create' element={<CreateEmpCancellation />} />
                  <Route path='edit/:id' element={<EditEmpCancellation />} />
                </Route>
                <Route path='final-settlement'>
                  <Route index element={<FinalSettlement />} />
                  <Route path='create' element={<CreateFinalSettlement />} />
                  <Route path='edit/:id' element={<EditFinalSettlement />} />
                </Route>
                <Route path='employee-studies' element={<EmployeeStudies />} />
                <Route path='training-transaction'>
                  <Route
                    index
                    element={<TrainingTransaction type={'HRMS'} />}
                  />
                  <Route
                    path='create'
                    element={<CreateTraningAndTransaction />}
                  />
                  <Route
                    path='edit/:id'
                    element={<EditTraningAndTransaction />}
                  />
                </Route>
                <Route path='certificates'>
                  <Route index element={<Certificates type={'HRMS'} />} />
                  <Route path='create' element={<CreateCertificates />} />
                  <Route path='edit/:id' element={<EditCertificates />} />
                </Route>
                <Route path='candidates'>
                  <Route index element={<Candidates type={'HRMS'} />} />
                  <Route
                    path='create'
                    element={<CreateCandidates type={'HRMS'} />}
                  />
                  <Route
                    path='edit/:id'
                    element={<EditCandidates type={'HRMS'} />}
                  />
                </Route>
                <Route path='candidates' element={<Candidates type={1} />} />
                <Route
                  path='employee-salary-calculations'
                  element={<EmployeeSalaryCalculations />}
                />

                <Route
                  path='attendance-summary'
                  element={<AttendanceSummary />}
                />
              </Route>
              {/* 
              <Route path='document-management'>
                <Route index element={<DocumentManagement type={'HRMS'} />} />
                <Route path='create' element={<CreateDocument />} />
              </Route> */}

              <Route path='job-requisition'>
                <Route
                  index
                  element={<Navigate replace to='/hrms/job-requisition/list' />}
                />
                <Route path='list' element={<JobRequisition />} />
                <Route path='list/create' element={<CreateJobRequisition />} />
                <Route path='list/edit/:id' element={<EditJobRequisition />} />
                <Route path='jobrecruitments'>
                  <Route index element={<JobRecruitments />} />
                  <Route path='create' element={<CreateJobRecruitment />} />
                  <Route path='edit/:id' element={<EditJobRecruitment />} />
                </Route>
                <Route path='candidates'>
                  <Route index element={<Candidates type={'HRMS'} />} />
                  <Route
                    path='create'
                    element={<CreateCandidates type={'HRMS'} />}
                  />
                  <Route
                    path='edit/:id'
                    element={<EditCandidates type={'HRMS'} />}
                  />
                  <Route
                    path='document-collection'
                    element={<DocumentCollection type={'HRMS'} />}
                  />
                  <Route
                    path='interview-assessment'
                    element={<InterviewAssessmentForm />}
                  />
                  <Route
                    path='candidate-confirmation'
                    element={<CandidateConfirmation />}
                  />
                </Route>
              </Route>

              <Route path='training-event'>
                <Route
                  index
                  element={<Navigate replace to='/hrms/training-event/list' />}
                />
                <Route
                  path='list'
                  element={<TrainingAndEvents type={'HRMS'} />}
                />
                <Route
                  path='list/create'
                  element={<CreateTrainingAndEvents />}
                />
                <Route
                  path='list/edit/:id'
                  element={<EditTrainingAndEvents />}
                />
              </Route>

              <Route path='masters'>
                <Route
                  index
                  element={<Navigate replace to='/hrms/masters/department' />}
                />
                <Route index element={<EmpAllMasters />} />
                <Route path='department' element={<DepartmentMasters />} />
                <Route path='designation' element={<DesignationMasters />} />
                <Route path='wps' element={<WpsMasters />} />
                <Route path='region' element={<RegionMasters />} />
                <Route path='location' element={<LocationMasters />} />
                <Route path='nationality' element={<NationalityMasters />} />
                <Route
                  path='experience-type'
                  element={<ExperienceTypeMasters />}
                />
                <Route path='contract-type' element={<ContractTypeMasters />} />
                <Route path='blood-group' element={<BloodGroupMasters />} />
                <Route
                  path='certificate-type'
                  element={<CertificateTypeMasters />}
                />
                <Route path='document-type' element={<DocumentTypeMasters />} />
                <Route
                  path='document-type-category'
                  element={<DocumentTypeCategoryMasters />}
                />

                <Route
                  path='cancellation-type'
                  element={<CancellationTypeMasters />}
                />
                <Route path='positions' element={<PositionsMaster />} />
                <Route path='companies' element={<CompanyMasters />} />
                <Route path='pay-groups' element={<PayGroupMaster />} />
                <Route path='cost-centers' element={<CostCenterMasters />} />
              </Route>

              <Route path='shift-time-master' element={<ShiftTimeMaster />} />
              <Route path='department-shifts' element={<DepartmentShifts />} />

              <Route path='dashboard' element={<Dashboard />} />
              <Route path='employee'>
                <Route
                  index
                  element={<Navigate replace to='/pr/employee/list' />}
                />
              </Route>

              <Route
                path='approval-workflow'
                element={<HRMSEmployeeMaster />}
              />
              <Route path='letter-management' />
              <Route
                path='leave-configuration'
                element={<LeaveConfiguration />}
              />
              <Route
                path='manpower-ratecard'
                element={<ManpowerRateCardList />}
              />
              <Route path='document-management'>
                <Route
                  index
                  element={
                    <DocumentsList type={process.env.REACT_APP_ENUM_HRMS} />
                  }
                />
                <Route
                  path='create'
                  element={
                    <CreateDocuments type={process.env.REACT_APP_ENUM_HRMS} />
                  }
                />
              </Route>
            </Route>
            {/* ----------------------PR---------------------------- */}
            <Route>
              <Route path='pr'>
                <Route
                  index
                  element={<Navigate replace to='/pr/dashboard' />}
                />
                <Route path='dashboard' element={<PRDashboard />} />
                <Route path='employee'>
                  <Route
                    index
                    element={<Navigate replace to='/pr/employee/list' />}
                  />
                  <Route
                    path='list'
                    element={<HRMSEmployeeMaster type={'PR'} />}
                  />
                  <Route path='list/create' element={<EmployeeCreate />} />
                  <Route path='list/edit/:id' element={<EmployeeUpdate />} />
                  <Route path='passport-request'>
                    <Route index element={<PassportRequest type={'PR'} />} />
                    <Route path='create' element={<CreatePassportRequest />} />
                    <Route path='edit/:id' element={<EditPassportRequest />} />
                  </Route>
                  <Route path='certificates'>
                    <Route index element={<Certificates type={'PR'} />} />
                    <Route path='create' element={<CreateCertificates />} />
                    <Route path='edit/:id' element={<EditCertificates />} />
                  </Route>
                  <Route path='candidates'>
                    <Route index element={<Candidates type={'PR'} />} />
                    <Route path='create' element={<CreateCandidates />} />
                    <Route path='edit/:id' element={<EditCandidates />} />
                    <Route
                      path='document-collection'
                      element={<DocumentCollection type={'PR'} />}
                    />
                    <Route
                      path='interview-assessment'
                      element={<InterviewAssessmentForm />}
                    />
                    <Route
                      path='candidate-confirmation'
                      element={<CandidateConfirmation />}
                    />
                  </Route>
                  <Route path='passport-visa'>
                    <Route index element={<PassportAndVisa />} />
                    <Route path='create' element={<CreatePassportAndVisa />} />
                    <Route path='edit/:id' element={<EditPassportAndVisa />} />
                  </Route>
                  <Route path='training-transaction'>
                    <Route
                      index
                      element={
                        <Navigate replace to='/pr/training-transaction/list' />
                      }
                    />
                    <Route
                      path='list'
                      element={<TrainingTransaction type={'PR'} />}
                    />
                    <Route
                      path='create'
                      element={<CreateTraningAndTransaction />}
                    />
                    <Route
                      path='edit/:id'
                      element={<EditTraningAndTransaction />}
                    />
                  </Route>
                </Route>
                <Route path='masters'>
                  <Route
                    index
                    element={<Navigate replace to='/pr/masters/card-types' />}
                  />
                  <Route path='card-types' element={<CardTypes />} />
                  <Route path='expense-type' element={<ExpenseTypePr />} />
                  <Route path='policies' element={<CompanyPolicies />} />
                  <Route path='policy-type' element={<CompanyPolicyTypes />} />
                  <Route path='end-user' element={<EndUsers />} />
                  <Route path='type-of-product' element={<TypeOfProducts />} />
                  <Route
                    path='premise-location-name'
                    element={<PremiseLocations />}
                  />
                  <Route
                    path='medical-insurance-location'
                    element={<MedicalInsuranceLocation />}
                  />
                  <Route
                    path='medical-insurance-status'
                    element={<MedicalInsuranceStatus />}
                  />
                  <Route
                    path='medical-insurance-age-band'
                    element={<MedicalInsuranceAgeBands />}
                  />
                  <Route
                    path='medical-insurance-salary-band'
                    element={<MedicalInsuranceSalaryBands />}
                  />
                  <Route
                    path='medical-insurance-policy'
                    element={<MedicalInsurancePolicies />}
                  />
                  <Route path='policy-premiums' element={<PolicyPremium />} />
                  <Route
                    path='medical-insurance-dependencies'
                    element={<MedicalInsuranceDependencies />}
                  />
                  <Route
                    path='medical-insurance-premise-purpose'
                    element={<MedicalInsurancePremisePurpose />}
                  />
                  <Route
                    path='medical-insurance-premise-types'
                    element={<MedicalInsurancePremiseTypes />}
                  />
                  <Route path='uniform-sizes' element={<UniformSizes />} />
                  <Route path='uniform-types' element={<UniformTypes />} />
                  <Route path='insurer' element={<Insurer />} />
                </Route>
                <Route path='training-and-event'>
                  <Route
                    index
                    element={
                      <Navigate replace to='/pr/training-and-event/list' />
                    }
                  />
                  <Route
                    path='list'
                    element={<TrainingAndEvents type={'PR'} />}
                  />
                  <Route
                    path='list/create'
                    element={<CreateTrainingAndEvents />}
                  />
                  <Route
                    path='list/edit/:id'
                    element={<EditTrainingAndEvents />}
                  />
                </Route>
                <Route path='vechicle-master'>
                  <Route index element={<VehicleMaster />} />
                  <Route path='create' element={<CreateVehicleMaster />} />
                  <Route path='edit/:id' element={<EditVehicleMaster />} />
                </Route>
                {/* <Route path='document-management'>
                  <Route index element={<DocumentManagement type={'PR'} />} />
                </Route> */}
                <Route path='quota-employees-summary'>
                  <Route index element={<QuotaEmployeesList />} />
                </Route>
                <Route path='cash-account-management'>
                  <Route
                    index
                    element={
                      <Navigate replace to='/pr/cash-account-management/list' />
                    }
                  />
                  <Route path='list' element={<CashAccountMgmt />} />
                  <Route
                    path='list/create'
                    element={<CreateCashAccountManagement />}
                  />
                  <Route
                    path='list/edit/:id'
                    element={<EditCashAccountManagement />}
                  />
                </Route>
                <Route path='transaction'>
                  <Route index element={<TransactionManagement />} />
                  <Route
                    path='create'
                    element={<CreateTransactionManagement />}
                  />
                  <Route
                    path='edit/:id'
                    element={<EditTransactionManagement />}
                  />
                  <Route
                    path='view/:id'
                    element={<ViewTransactionManagement />}
                  />
                </Route>
                <Route path='document-management'>
                  <Route
                    index
                    element={
                      <DocumentsList type={process.env.REACT_APP_ENUM_PR} />
                    }
                  />
                  <Route
                    path='create'
                    element={
                      <CreateDocuments type={process.env.REACT_APP_ENUM_PR} />
                    }
                  />
                </Route>
              </Route>
            </Route>
            {/* --------------------PreSales-------------------- */}
            <Route path='presales'>
              <Route path='dashboard' element={<Dashboard />} />
              <Route path='enquiry'>
                <Route index element={<Enquiry />} />
                <Route path='create' element={<CreateEnquiry />} />
                <Route path='view/:id' element={<ViewEnquiry />} />
                <Route path='edit/:id' element={<EditEnquiry />} />
              </Route>
              <Route path='manage-contracts'>
                <Route index element={<ManageContracts />} />
                <Route path='create' element={<CreateContracts />} />
                <Route path='edit/:id' element={<EditContracts />} />
                <Route path='view/:id' element={<ViewContracts />} />
              </Route>

              <Route path='clients'>
                <Route index element={<Clients />} />
                <Route path='create' element={<Createclient />} />
                <Route path='edit/:id' element={<EditClients />} />
              </Route>

              <Route path='suppliers'>
                <Route index element={<Suppliers />} />
                <Route path='create' element={<CreateSuppliers />} />
                <Route path='view/:id' element={<ViewSuppliers />} />
                <Route path='edit/:id' element={<EditSuppliers />} />
                <Route path='add-contacts/:id' element={<SuppliersSpoc />} />
              </Route>
              <Route path='projects'>
                <Route index element={<Projects />} />
                <Route path='create' element={<CreateProjects />} />
                <Route path='view/:id' element={<ViewProjects />} />
                <Route path='edit/:id' element={<EditProjects />} />
              </Route>
              <Route path='tender-lead-enquiry'>
                <Route index element={<TenderEnquiry />} />
                <Route path='create' element={<CreateTenderEnquiry />} />
                <Route path='edit/:id' element={<EditTenderEnquiry />} />
              </Route>
              <Route path='tender-inspection-checklist'>
                <Route index element={<TenderInspectionChecklist />} />
                <Route
                  path='create'
                  element={<CreateTenderInspectionChecklist />}
                />
                <Route
                  path='view/:id'
                  element={<ViewTenderCheckListDetail />}
                />
                <Route
                  path='edit/:id'
                  element={<EditTenderInspectionChecklist />}
                />
              </Route>
              <Route path='estimation-sheet-amc'>
                <Route index element={<EstimationSheetForAMC />} />
                <Route
                  path='create'
                  element={<CreateEstimationSheetForAMC />}
                />
                <Route path='edit/:id' element={<EditEstimationSheet />} />
              </Route>
              <Route path='estimation-sheet-mep'>
                <Route index element={<EstimationSheetForMEP />} />
                <Route
                  path='create'
                  element={<CreateEstimationSheetForMEP />}
                />
                <Route path='view/:id' element={<ViewEstimationMEP />} />
                <Route path='edit/:id' element={<EditEstimationSheetMEP />} />
              </Route>
              <Route path='CRUDEnquiry' />
              <Route
                path='manage-document'
                element={<DocumentManagement type={3} />}
              />
              <Route path='BidbondNotification' />
              <Route path='proposal'>
                <Route index element={<ProposalList />} />
                <Route path='create' element={<ProposalCreate />} />
                <Route
                  path='view/:id'
                  element={<ProposalView type={'view'} />}
                />
                <Route
                  path='edit/:id'
                  element={<ProposalView type={'edit'} />}
                />
              </Route>
              <Route path='document-management'>
                <Route
                  index
                  element={
                    <DocumentsList type={process.env.REACT_APP_ENUM_PRESALES} />
                  }
                />
                <Route
                  path='create'
                  element={
                    <CreateDocuments
                      type={process.env.REACT_APP_ENUM_PRESALES}
                    />
                  }
                />
              </Route>
              <Route path='masters'>
                <Route
                  index
                  element={
                    <Navigate
                      replace
                      to='/presales/masters/tender-enquiry-types'
                    />
                  }
                />
                <Route
                  path='tender-enquiry-types'
                  element={<TenderEnquiryTypes />}
                />
                <Route
                  path='tender-project-types'
                  element={<TenderProjectTypes />}
                />
                <Route path='tender-formats' element={<TenderFormats />} />
                <Route path='countries' element={<CountryMaster />} />
                <Route path='supplier-types' element={<SupplierTypes />} />
                <Route
                  path='tender-followup-types'
                  element={<TenderFollowUpTypesMasters />}
                />
                <Route path='tender-status' element={<TenderStatusMasters />} />
                <Route path='contact-modes' element={<ContactModesMasters />} />
                <Route
                  path='regions'
                  element={<RegionMasters type={'presales'} />}
                />
                <Route
                  path='buildings'
                  element={<Building type={'presales'} />}
                />
                <Route
                  path='locations'
                  element={<LocationMasters type={'presales'} />}
                />
                <Route
                  path='departments'
                  element={<DepartmentMasters type={'presales'} />}
                />
              </Route>
            </Route>
            {/* --------------------Cafm-------------------- */}
            <Route path='cafm'>
              <Route
                index
                element={<Navigate replace to='/cafm/dashboard' />}
              />
              <Route path='dashboard' element={<Dashboard />} />
              <Route path='tickets'>
                <Route index element={<CafmTicket />} />
                <Route path='create' element={<CreateTicket />} />
              </Route>

              <Route path='inventory-management'>
                <Route index element={<InventoryList />} />
                <Route path='create' element={<AddInventory />} />
                <Route path='edit/:id' element={<AddInventory />} />
                <Route path='view/:id' element={<InventoryView />} />
              </Route>

              <Route path='asset-history'>
                <Route index element={<AssetHistory />} />
                <Route path='view' element={<AssetHistoryView />} />
              </Route>
            </Route>
            {/* --------------------Procurement-------------------- */}
            <Route path='procurement'>
              <Route
                index
                element={<Navigate replace to='/procurement/dashboard' />}
              />
              <Route path='dashboard' element={<Dashboard />} />
              <Route path='material-request'>
                <Route index element={<MaterialRequestList />} />
                <Route path='create' element={<CreateMaterialRequest />} />
                <Route path='edit/:id' element={<CreateMaterialRequest />} />
                <Route path='view/:id' element={<ViewMaterialRequest />} />
              </Route>
              <Route path='inventory-management'>
                <Route
                  index
                  element={
                    <Navigate
                      replace
                      to='/procurement/inventory-management/store-locations'
                    />
                  }
                />

                <Route path='store-locations'>
                  <Route index element={<StoreLocations />} />
                </Route>
                <Route path='inventory-items'>
                  <Route index element={<InventoryList />} />
                  <Route path='create' element={<AddInventory />} />
                  <Route path='edit/:id' element={<AddInventory />} />
                  <Route path='view/:id' element={<InventoryView />} />
                </Route>
              </Route>
              <Route path='masters'>
                <Route
                  index
                  element={
                    <Navigate replace to='/procurement/masters/building' />
                  }
                />
                <Route path='building' element={<Building />} />
                <Route path='levels' element={<Levels />} />
                <Route path='levelUnits' element={<LevelUnits />} />
                <Route path='costCenters' element={<CostCenters />} />
                <Route path='supplier-items' element={<SupplierItems />} />
              </Route>
              <Route path='item-management' element={<ItemManagementList />} />
              <Route path='purchase-order-view'>
                <Route index element={<PurchaseOrderViewList />} />
                <Route path='create' element={<CreatePurchaseOrderView />} />
                <Route path='edit/:id' element={<CreatePurchaseOrderView />} />
                <Route path='preview/:id' element={<PurchaseOrderPreview />} />
                <Route path='send/:id' element={<PurchaseOrderViewSendLPO />} />
              </Route>
              <Route path='request-for-quote-management'>
                <Route index element={<RequestQuoteManagement />} />
                <Route path='create' element={<CreateRequestQuote />} />
                <Route path='edit/:id' element={<CreateRequestQuote />} />
                {/* <Route path='preview/:id' element={<PurchaseOrderPreview />} />
                <Route path='send' element={<PurchaseOrderViewSendLPO />} /> */}
              </Route>
              <Route path='goods-received-note'>
                <Route index element={<GoodsReceivedNoteList />} />
                <Route path='create' element={<GoodsReceivedNotesCreate />} />
                <Route path='edit/:id' element={<GoodsReceivedNotesCreate />} />
              </Route>
              <Route
                path='document-management'
                element={<DocumentManagement type={9} />}
              />
            </Route>
            {/*------------------Budgeting------------------*/}
            <Route path='/Budgeting/dashboard'>
              <Route index element={<UnderConstruction />} />
            </Route>
            {/*------------------Budgeting------------------*/}
            {/*------------------Financial------------------*/}
            <Route path='/financial'>
              <Route
                index
                element={<Navigate replace to='/financial/dashboard' />}
              />
              <Route path='dashboard' element={<UnderConstruction />} />

              <Route path='accounts-receivable'>
                <Route
                  index
                  element={
                    <Navigate
                      replace
                      to={'/financial/accounts-receivable/purchase-voucher'}
                    />
                  }
                />
                <Route path='purchase-voucher' element={<PurchaseVoucher />} />
                <Route
                  path='purchase-return-voucher'
                  element={<PurchaseVoucher />}
                />
                <Route
                  path='direct-purchase-order'
                  element={<PurchaseVoucher />}
                />
                <Route path='sales-order' element={<SalesOrder />} />
                <Route path='sales-return' element={<SalesOrder />} />
                <Route path='sales-retention' element={<SalesRetention />} />
              </Route>
            </Route>
            {/*------------------Financial------------------*/}
            {/*------------------HSE------------------*/}
            <Route path='/HSE/dashboard'>
              <Route index element={<UnderConstruction />} />
            </Route>
            {/*------------------HSE------------------*/}
            {/*------------------Admin------------------*/}
            <Route path='/admin'>
              <Route
                index
                element={<Navigate replace to='/admin/dashboard' />}
              />
              <Route path='dashboard' element={<Dashboard />} />
              <Route path='company-insurance-policy'>
                <Route
                  index
                  element={
                    <Navigate
                      replace
                      to='/admin/company-insurance-policy/list'
                    />
                  }
                />
                <Route path='list' element={<CompanyInsurancePolicyList />} />
                <Route path='comparison' element={<ComparisonPolicy />} />
              </Route>
              <Route path='company-medical-insurance-policy'>
                <Route
                  index
                  element={
                    <Navigate
                      replace
                      to='/admin/company-medical-insurance-policy/list'
                    />
                  }
                />
                <Route path='list'>
                  <Route index element={<CompanyMedicalInsurancePolicy />} />
                  <Route
                    path='create'
                    element={<CreateCompanyMedicalInsurance />}
                  />
                </Route>
                <Route
                  path='medical-insurance-policy-premium-calculator'
                  element={<MIPPremiumCalculator />}
                />
                <Route
                  path='employee-wise-premium-calculator'
                  element={<EmployeeWiseMIPCalculator />}
                />
              </Route>
              <Route path='accommodation' element={<AccommodationList />} />
              <Route
                path='uniform-accessories-requistions'
                element={<UniformRequistionList />}
              />
              <Route path='events-calendar'>
                <Route index element={<EventsCalender />} />
                <Route path='events-budget' element={<ViewEventsCalendar />} />
              </Route>
            </Route>
            {/*------------------Project Division------------------*/}
            <Route path='/project-Division'>
              <Route
                index
                element={<Navigate replace to='/project-Division/dashboard' />}
              />
              <Route path='dashboard' element={<Dashboard />} />
              <Route path='project-division'>
                <Route index element={<ProjectDivisionList />} />
                <Route
                  path='manage-assignee'
                  element={<ManageAssigneeList />}
                />
                <Route path='project-plan' element={<ProjectPlanList />} />
                <Route
                  path='add-project-activity/:id'
                  element={<ProjectActivityList />}
                />
                <Route path='project-cost-tracking'>
                  <Route
                    index
                    element={
                      <Navigate
                        replace
                        to='/project-Division/project-division/project-cost-tracking/project-main-cost'
                      />
                    }
                  />
                  <Route
                    path='project-main-cost'
                    element={<ProjectMainCost />}
                  />
                  <Route
                    path='budget'
                    element={<BudgetProjectCostTracking />}
                  />
                  {/* This is Common Component for all */}
                  <Route
                    path='Materials/:id'
                    element={<ManpowerProjectCostTracking type={'materials'} />}
                  />
                  <Route
                    path='manpower/:id'
                    element={<ManpowerProjectCostTracking type={'manpower'} />}
                  />
                  <Route
                    path='machineries/:id'
                    element={
                      <ManpowerProjectCostTracking type={'machineries'} />
                    }
                  />
                  <Route
                    path='general/:id'
                    element={<ManpowerProjectCostTracking type={'general'} />}
                  />
                  <Route
                    path='others/:id'
                    element={<ManpowerProjectCostTracking type={'others'} />}
                  />
                  {/* This is Common Component for all */}
                  <Route
                    path='PettyCash/:id'
                    element={<PettyCashProjectCostTracking />}
                  />
                </Route>
              </Route>
              <Route path='masters'>
                <Route
                  index
                  element={
                    <Navigate
                      replace
                      to='/project-Division/masters/project-boq-head-master'
                    />
                  }
                />
                <Route
                  path='project-boq-head-master'
                  element={<BoqHeadMasterList />}
                />
                <Route
                  path='project-boq-package-master'
                  element={<BoqPackageMaster />}
                />
                <Route
                  path='project-boq-sub-head-master'
                  element={<BoqSubHeadMaster />}
                />
                <Route
                  path='boq-line-item-master'
                  element={<BoqLineItemMaster />}
                />
              </Route>
            </Route>
            {/*------------------ELV------------------*/}
            <Route path='/elv'>
              <Route index element={<Navigate replace to='/elv/dashboard' />} />
              <Route path='dashboard' element={<Dashboard />} />
              <Route path='inspection-report'>
                <Route index element={<InspectionReport />} />
                <Route path='view' element={<ViewInspectionReport />} />
              </Route>
              <Route path='incident-report'>
                <Route index element={<IncidentReport />} />
                <Route path='incident' element={<ViewIncident />} />
                <Route path='injuries' element={<ViewInjuries />} />
                <Route path='remarks' element={<ViewIncidentRemarks />} />
              </Route>
              <Route path='snag'>
                <Route
                  index
                  element={<Navigate replace to='/elv/snag/snag-report' />}
                />
                <Route path='snag-report'>
                  <Route index element={<SnagReport />} />
                  <Route path='snag-tab' element={<SnagTab />} />
                  <Route
                    path='functional-test-comments-tab'
                    element={<SnagTab />}
                  />
                </Route>
                <Route path='create-check-in-form'>
                  <Route index element={<CreateCheckInForm type='checkin' />} />
                  <Route path='view' element={<ViewCreateCheckInForm />} />
                </Route>
                <Route path='create-check-out-form'>
                  <Route
                    index
                    element={<CreateCheckInForm type='checkout' />}
                  />
                  <Route path='view' element={<CreateCheckoutView />} />
                </Route>
              </Route>
            </Route>
            {/*------------------FM Division------------------*/}
            <Route path='/fm-Division'>
              <Route
                index
                element={<Navigate replace to='/fm-Division/dashboard' />}
              />
              <Route path='dashboard' element={<Dashboard />} />
              <Route path='work-order'>
                <Route
                  index
                  element={
                    <Navigate
                      replace
                      to='/fm-Division/work-order/create-work-order'
                    />
                  }
                />
                <Route path='create-work-order' element={<CreateWorkOrder />} />
                <Route path='create-sales-quotation'>
                  <Route index element={<CreateSalesQuotation />} />
                  <Route path='view' element={<ViewCreateSalesQuotation />} />
                </Route>
                <Route path='create-sales-order'>
                  <Route index element={<CreateSalesOrder />} />
                  <Route path='view' element={<ViewSalesOrder />} />
                </Route>
              </Route>
            </Route>
            {/*------------------ESS Portal------------------*/}
            <Route path='essPortal'>
              <Route index element={<EssLogin />} />
              <Route path='dashboard' element={<EssDashboard />} />
            </Route>{' '}
          </Routes>
        </Router>{' '}
      </SettingsProvider>
    </>
  );
};

export default App;
